$(document).foundation();

function setM(checked) {
    var elm = document.getElementById('menu-btn');
    if (checked !== elm.checked) {
        elm.click();
    }
}

$(document).ready(function () {

    $('#bird').animate({opacity: 1.00},5000);
    




});


